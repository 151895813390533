import { FC } from "react"
import styles from "./footer.module.scss"

interface SocialLink {
  href: string
  icon: string
  label: string
}

const socialLinks: SocialLink[] = [
  {
    href: "https://www.linkedin.com/in/mircea-dumitrescu-8581399a/",
    icon: "fab fa-linkedin",
    label: "Visit my LinkedIn profile",
  },
  {
    href: "https://github.com/MirceaDumitrescu/",
    icon: "fab fa-github",
    label: "Visit my GitHub profile",
  },
  {
    href: "https://www.facebook.com/mirceagab/",
    icon: "fab fa-facebook",
    label: "Visit my Facebook profile",
  },
]

export const Footer: FC = () => {
  const currentYear = new Date().getFullYear()

  return (
    <div className={styles.footerWrapper}>
      <footer className={styles.footer} role="contentinfo">
        <div className={styles.footer__content}>
          <p className={styles.footer__copyright}>
            <span aria-label="Copyright">&copy;</span> {currentYear} Dumitrescu Mircea
          </p>
          <nav className={styles.footer__social} aria-label="Social media links">
            {socialLinks.map(({ href, icon, label }) => (
              <a
                key={href}
                href={href}
                rel="noopener noreferrer"
                target="_blank"
                aria-label={label}
                className={styles.footer__link}
              >
                <span className={`${styles.footer__icon} ${icon}`} aria-hidden="true" />
              </a>
            ))}
          </nav>
        </div>
      </footer>
    </div>
  )
}

export default Footer
