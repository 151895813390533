import { lazy, Suspense } from "react"
import { BrowserRouter as Router } from "react-router-dom"
import { Footer } from "./Components/Footer/Footer"
import Spinner from "./Components/Spinner/Spinner"
import styles from "./app.module.scss"
import "./App.scss"

// Lazy load components
const Navbar = lazy(() => import("./Components/Navbar/Navbar"))
const PublicRoutes = lazy(() => import("./Router"))

const App = () => {
  return (
    <Router>
      <div className={styles.app}>
        <Suspense fallback={<Spinner size="medium" />}>
          <Navbar />
        </Suspense>
        <Suspense fallback={<Spinner size="large" className={styles.mainContent} />}>
          <main className={styles.mainContent}>
            <PublicRoutes />
          </main>
        </Suspense>
        <Footer />
      </div>
    </Router>
  )
}

export default App
