import { FC } from "react"
import styles from "./spinner.module.scss"

interface SpinnerProps {
  size?: "small" | "medium" | "large"
  className?: string
}

export const Spinner: FC<SpinnerProps> = ({ size = "medium", className = "" }) => {
  return (
    <div className={`${styles.wrapper} ${className}`}>
      <div
        className={`${styles.spinner} ${styles[`spinner--${size}`]}`}
        role="progressbar"
        aria-label="Loading..."
      />
    </div>
  )
}

export default Spinner
